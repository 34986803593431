import {Request} from './model/request';

export class FetchService {

    public static executeEndpoint(request: Request): void {
        const xhr = new XMLHttpRequest();
        let url=request.getUrl();
        if(!url.startsWith("http")){
            url=FetchService.getAppLink()+url;
        }
        xhr.open(request.getMethod(), url);
        if (!request.getIsRefresh()) {
            const access_token = this.getAccessToken();
            if (!access_token) {
                if (request.getFail()) {
                    request.getFail()("Su sesi�n ha caducado. Vuelva a ingresar");
                }
                return;
            }
            xhr.setRequestHeader('Authorization', 'Bearer ' + this.getAccessToken());
        }
        for (let xKey in request.getHeaders()) {
            xhr.setRequestHeader(xKey, request.getHeaders()[xKey]);
        }
        xhr.onload = function (e) {
            if (this.status == 200) {
                if (request.getSuccess()) {
                    request.getSuccess()(this.response);
                }
            } else if (this.status == 401 && !request.getIsRefresh()) {
                FetchService.execute_refreshAccessToken(request);
            } else if (request.getFail()) {
                request.getFail()(this.response);
            }
        };
        if (request.getBody()) {
            xhr.send(request.getBody());
        } else {
            xhr.send();
        }
    }

    public static execute_refreshAccessToken(oldRequest: Request): void {

        const request = new Request();
        request.setIsRefresh(true);
        request.setUrl("oauth/token");
        request.setSuccess((response) => {
            let data = JSON.parse(response);
            localStorage.setItem(this.getNuSecHidden(), data.access_token);
            localStorage.setItem(this.getNuSecHidden() + '-refresh', data.refresh_token);
            this.executeEndpoint(oldRequest);
        });
        request.setFail(function (response) {
            localStorage.removeItem(this.getNuSecHidden());
            localStorage.removeItem(this.getNuSecHidden() + '-refresh');
            if (oldRequest.getFail()) {
                oldRequest.getFail()(response);
            }
        });
        request.setBody("grant_type=refresh_token&refresh_token=" + this.getRefresh_token());
        request.setMethod("POST");
        request.setHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            'deyel-agent': 'Deyel',
            'Accept': '*/*',
            'Authorization': 'Basic ' + btoa($("#cdUserHidden").val() + ":")
        });
        this.executeEndpoint(request);
    }

    public static getNuSecHidden(): string {
        let nuSecHidden = (<HTMLInputElement>document.getElementById('nuSecHidden'));
        if (nuSecHidden == null) {
            let nuSecHiddenVal = <string>document.getElementsByTagName("head")[0].getAttribute("nuSecHidden");
            if (nuSecHiddenVal != null) {
                return nuSecHiddenVal;
            }

            if (window && window.parent) {
                nuSecHidden = <HTMLInputElement>window.parent.document.getElementById('nuSecHidden');
            }
            if (!window || !window.parent || !nuSecHidden) {
                return null;
            }
        }
        return nuSecHidden.value;
    }

    public static getAccessToken(): string {
        return localStorage.getItem(this.getNuSecHidden());
    }

    public static getRefresh_token(): string {
        return localStorage.getItem(this.getNuSecHidden() + '-refresh');
    }

    public static getAppLink(): string {
        const element: HTMLElement | null = FetchService.findElementById('APPLICATION_LINK');
        if (element != null) {
            return (<HTMLInputElement>element).value;
        } else {
            let appLink = window.location.origin + "/";
            let path = window.location.pathname;
            if ((path.match(/\//g) || []).length > 1) {
                appLink += "/" + path.split("/")[1] + "/";
            }
            return appLink;
        }
    }

    public static findElementById(elementId: string): HTMLElement | null {
        let currentWindow: Window | null = window;
        while (currentWindow) {
            const element: HTMLElement | null = currentWindow.document.getElementById(elementId);
            if (element) {
                return element;
            }
            if (currentWindow === currentWindow.parent) {
                break;
            }
            currentWindow = currentWindow.parent;
        }
        return null;
    }
}